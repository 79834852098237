body {
  margin: 0;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.pointer {
  cursor: pointer;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.css-13cymwt-control {
  background-color: rgb(37 48 53) !important;
  border: 1px solid #253035 !important;
  min-height: 54px !important;
  border-radius: 8px !important;
}

.css-13cymwt-control-error {
  background-color: rgb(37 48 53) !important;
  border: 1px solid red !important ;
  min-height: 54px !important;
  border-radius: 8px !important;
}

.css-dd-container {
  background-color: rgb(37 48 53) !important;
}

.css-b62m3t-container{
  width: 100%;
}
.not_initiated{
  color: darkorange;
  font-weight: 600;
}
.video_complete{
  color: #5de906;
  font-weight: 600;
}
.ql-container {
  box-sizing: border-box;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 13px;
  height: 100%;
  margin: 0px;
  position: relative;
  min-height: 110px;
  border: 1px solid #253035 !important;
  background-color: rgb(37 48 53) !important;
}

.ql-toolbar.ql-snow {
  border: 1px solid #253035 !important;
  box-sizing: border-box;
  font-family: "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  padding: 8px;
}

.error-field {
  color: red;
}

.pagination {
  --bs-pagination-disabled-color: #6c757d;
  --bs-pagination-disabled-bg: transparent;
}

.css-t3ipsp-control {
  background-color: #253035 !important;
  border: 1px solid #253035 !important;
  min-height: 54px !important;
}
.css-1nmdiq5-menu {
  background-color: #253035 !important;
  border: 1px solid #253035 !important;
}
#react-select-2-listbox {
  background-color: #253035 !important;
  border: 1px solid #253035 !important;
}

#react-select-2-listbox:selection {
  background-color: #1f93c9 !important;
  border: 1px solid #253035 !important;
}
.star {
  color: red;
}

.css-d7l1ni-option {
  background-color: #83bb41 !important;
}

.css-1p3m7a8-multiValue {
  background-color: #83bb41 !important;
}
.css-1u9des2-indicatorSeparator {
  background-color: transparent !important;
}
.css-9jq23d {
  color: white !important;
}

.css-1u9des2-indicatorSeparator {
  background-color: transparent !important;
}
.css-wsp0cs-MultiValueGeneric {
  color: white !important;
}

#react-select-2-input {
  color: white !important;
}

#react-select-3-input {
  color: white !important;
}

#react-select-4-input {
  color: white !important;
}
#react-select-5-input {
  color: white !important;
}

#react-select-6-input {
  color: white !important;
}

#react-select-7-input {
  color: white !important;
}

#react-select-8-input {
  color: white !important;
}

div[disabled] {
  pointer-events: none;
  opacity: 0.7;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.pointer {
  cursor: pointer;
}

.bg-danger-75 {
  background-color: #462226;
}
.d-table-caption {
  display: table-caption !important;
}

.form-select{
  background-image:url("/public/images/envelope.svg") !important;
}

.form-select-filter{
  width:150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
/* toggle button styles */

.switch-toggle {
  position: relative;
  display: inline-block;
  width: 28px;
  height: 16px;
}

.switch-toggle input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider-toggle {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #253035;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider-toggle-off {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #253035;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider-toggle:before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  left: 4px;
  bottom: 2px;
  background-color: #fff;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider-toggle-off:before{
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  left: 4px;
  bottom: 2px;
  background-color: #171616;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider-toggle {
  background-color: #83bb41;
}

input:focus + .slider-toggle {
  box-shadow: 0 0 1px #83bb41;
}

input:checked + .slider-toggle:before {
  -webkit-transform: translateX(10px);
  -ms-transform: translateX(10px);
  transform: translateX(10px);
}

input:checked + .slider-toggle-off:before {
  -webkit-transform: translateX(10px);
  -ms-transform: translateX(10px);
  transform: translateX(10px);
}

/* Rounded sliders */
.slider-toggle.round-toggle {
  border-radius: 34px;
}

.slider-toggle.round-toggle:before {
  border-radius: 50%;
}

.slider-toggle-off.round-toggle {
  border-radius: 34px;
}

.slider-toggle-off.round-toggle:before {
  border-radius: 50%;
}

.css-1dimb5e-singleValue {
  color: #fff !important;
}
.css-qbdosj-Input{
  color: #fff !important;
}
.css-qbdosj-Input.input{
  color: #fff !important;
}

.css-13cymwt-control.input{
  color: #fff !important;
}

.css-t3ipsp-control input{
  color: #fff !important;
}

.css-9jq23d{
  white-space: normal !important;
   
 }



